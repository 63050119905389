body {
  overflow: hidden;
}
html,
body {
  box-sizing: border-box;
  margin: 0;
}
@font-face {
  font-family: "Suisse-Intl";
  font-weight: 400;
  src: local("Suisse-Intl"),
    url(./fonts/suisseintl/SuisseIntl-Regular.otf) format("truetype");
}

@font-face {
  font-family: "Suisse-Intl";
  font-weight: 800;
  src: local("Suisse-Intl"),
    url(./fonts/suisseintl/SuisseIntl-Bold.otf) format("truetype");
}

@font-face {
  font-family: "Suisse-Intl";
  font-weight: 600;
  src: local("Suisse-Intl"),
    url(./fonts/suisseintl/SuisseIntl-SemiBold.otf) format("truetype");
}
